.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(51, 51, 51, 0.53);
}

.closeBtn {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #e13e76;
    cursor: pointer;
}

.editForm {
    width: 310px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    position: relative;
    margin-top: 10vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    padding: 20px 0px;
}

.editForm ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .editForm {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.item1 {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.noPic {
    width: 80px;
    height: 80px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilePic {
    width: 80px;
    height: 80px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profImg[type="file"] {
    display: none;
}

.label {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    background-color: white;
}

.label label {
    cursor: pointer;
}

.item2 {
    width: 90%;
    height: 35px;
    min-height: 35px;
    position: relative;
}

.checkValidate {
    position: absolute;
    top: 0;
    left: 0;
    width: 7%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.item2 input {
    font-family: "vazir", sans-serif;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: #333;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    direction: rtl;
    text-align: center;
    font-weight: 800;
}

.editForm>h2 {
    width: 100%;
    margin: 10px 0px;
    text-align: right;
    font-family: "vazir", sans-serif;
    font-size: medium;
    font-weight: 400;
    padding-right: 20px;
}

.doneEdite {
    width: 60%;
    height: 35px;
    min-height: 35px;
    margin-top: 20px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background: #e13e76;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    color: white;
    cursor: pointer;
}