 .detailsItem2 {
     width: 100%;
     background-color: white;
     border-radius: 15px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 20px 7px;
     margin-bottom: 10px;
     font-family: "vazir", sans-serif;
 }
 
 .detailsItem2 .studentContain {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
 }
 
 .detailsItem2 .studentContain .content {
     font-weight: 700;
     font-size: x-large;
 }
 
 .detailsItem2 .schoollevelContain {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
 }
 
 .detailsItem2 .schoollevelContain .label {
     font-weight: 700;
     margin-left: 4px;
 }