.selectedCourse {
    width: 80%;
    height: 65px;
    min-height: 65px;
    border-radius: 7px;
    background-color: #ffb3c1;
    color: #333;
    font-family: "vazir";
    font-weight: 600;
    font-size: small;
    position: relative;
    display: flex;
    margin-left: 17px;
    margin-bottom: 5px;
}

.number {
    width: 15px;
    height: 65px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    right: -17px;
    top: 0;
}

.editeBtn {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    left: -32px;
    top: 0;
    cursor: pointer;
}

.delBtn {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    left: -32px;
    bottom: 0;
    cursor: pointer;
}

.detailsCon {
    width: 100%;
    height: 100%;
    padding: 5px;
    text-align: justify;
    line-height: 0.6cm;
    font-family: "vazir";
    font-weight: 500;
    font-size: x-small;
    color: rgb(63, 63, 63);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.conCon::-webkit-scrollbar {
    display: none;
}