.section {
    width: 100%;
    font-family: "vazir", sans-serif;
    padding: 20px 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
}

.label {
    font-weight: 900;
    font-size: large;
    margin-left: 10px;
}

.content {
    font-weight: 600;
    font-size: large;
}