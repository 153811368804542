.selectListContain {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 500px;
    min-width: 70px;
    min-height: 30px;
    font-family: "vazir", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
}

.inputContain {
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-family: "vazir", sans-serif;
    padding: 2px 7px;
    cursor: pointer;
}

.selectedLabel {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 500;
    cursor: pointer;
    color: #333;
}

.selectedLabel>p {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.downIcon {
    width: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #333;
    font-family: "vazir", sans-serif;
}

.listContain {
    width: 100%;
    max-height: 150px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: white;
    font-family: "vazir", sans-serif;
    position: absolute;
    top: calc(100% + 6px);
    z-index: 100;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

.listContain ::-webkit-scrollbar {
    display: none;
}

.listItem {
    width: 100%;
    height: 30px;
    min-height: 30px;
    border-radius: 7px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    text-align: center;
    background-color: white;
    transition: all 0.2s;
    padding-top: 0px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 500;
}

.listItem:hover {
    background-color: rgb(119, 126, 128);
}