.Container1 {
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.LoginContainer {
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    min-height: 800px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}