/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    border-radius: 10px;
}

*,
 ::after,
 ::before {
    box-sizing: border-box;
}

button:active {
    border-style: none;
}

:focus-visible {
    outline: none;
}

button {
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    margin: 0em;
    padding: 7px 10px;
    padding-top: 9px;
    border-width: none;
    border-style: none;
    border-color: none;
    border-image: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    transition: all 0.3s;
}

a {
    text-decoration: none;
}

img {
    font-family: 'tahoma';
    font-weight: 300;
    line-height: 2;
    text-align: center;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
}

img:after {
    content: "\F1C5" " " attr(alt);
    font-size: 16px;
    font-family: FontAwesome, tahoma;
    color: rgb(100, 100, 100);
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

html {
    scroll-behavior: smooth;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}
* {
    font-family: "vazir";
}

body {
    width: 100%;
    min-height: 100vh;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100vw;
    min-height: 100vh;
}

:root {
    --bgColor1: rgb(69, 90, 100);
    --bgColor2: #546e7a;
    --bgColor3: rgb(96, 125, 139);
    --bgColor4: rgb(120, 144, 156);
    --bgColor5: rgb(144, 164, 174);
    --bgColor6: rgb(176, 190, 197);
    --bgColor7: rgb(207, 216, 220);
    --bgColor8: rgb(236, 239, 241);
    --texColor1: #37474f;
    --texColor2: #546e7a;
    --texColor3: #eceff1;
    --boxShadow1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --boxShadow2: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    --boxShadow3: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    --CboxShadow1: rgba(84, 110, 122, 0.3) 0px 1px 2px 0px, rgba(84, 110, 122, 0.15) 0px 2px 6px 2px;
    --CboxShadow2: rgba(84, 110, 122, 0.16) 0px 3px 6px, rgba(84, 110, 122, 0.23) 0px 3px 6px;
    --CboxShadow3: rgba(84, 110, 122, 0.493) 0px 10px 20px, rgba(84, 110, 122, 0.445) 0px 6px 6px;
}

.coFlexC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coFlexS {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.roFlexC {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.roFlexE {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.roFlexB {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.roFlexS {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}


/* Hide scrollbar for IE, Edge and Firefox */

.hideScroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */

.hideScroll::-webkit-scrollbar {
    display: none;
}

.noneDisplay {
    display: none;
}

.vfxxlarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: xx-large;
}

.vfxxlarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-large;
}

.vfxxlarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
}

.vfxlarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: x-large;
}

.vfxlarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: x-large;
}

.vfxlarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: x-large;
}

.vflarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: large;
}

.vflarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: large;
}

.vflarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
}

.vfmedium1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: medium;
}

.vfmedium2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
}

.vfmedium3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: medium;
}

.vfsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: small;
}

.vfsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: small;
}

.vfsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: small;
}

.vfxsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: x-small;
}

.vfxsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: x-small;
}

.vfxsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: x-small;
}

.vfxxsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: xx-small;
}

.vfxxsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small;
}

.vfxxsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-small;
}

.textOver {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

input {
    direction: ltr;
}

.rmdp-container {
    font-family: "vazir", sans-serif;
    color: #333;
    width: 100%;
    height: 100%;
}

.rmdp-input {
    font-family: "vazir", sans-serif;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 0px;
}
.form1_formContainer__1OLPc {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.form1_formContainer__1OLPc h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    margin-bottom: 20px;
    text-align: center;
}

.form1_formContainer__1OLPc h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: large;
    color: #015275;
}

.form1_formContainer__1OLPc .form1_form__1db6s {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form1_formContainer__1OLPc .form1_form__1db6s input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form1_formContainer__1OLPc .form1_form__1db6s input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form1_formContainer__1OLPc .form1_form__1db6s input:-ms-input-placeholder {
    color: #0085bec7;
}

.form1_formContainer__1OLPc .form1_form__1db6s input::placeholder {
    color: #0085bec7;
}

.form1_formContainer__1OLPc .form1_form__1db6s input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form1_formContainer__1OLPc .form1_form__1db6s input:invalid:after {
    position: absolute;
    content: '\2716';
    padding-left: 5px;
}

.form1_formContainer__1OLPc .form1_form__1db6s input:valid:after {
    position: absolute;
    content: '\2713';
    padding-left: 5px;
}

.form1_formContainer__1OLPc button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.form1_rulesContainer__1nU26 {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.form1_rulesContainer__1nU26 p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #015275;
}

.form1_rulesContainer__1nU26 .form1_rulesLink__3r1Pc {
    margin: 0 4px;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

@-moz-document url-prefix() {
    .form1_formContainer__1OLPc {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.form2_formContainer__1IKFH {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.form2_back__142Cq {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.form2_formContainer__1IKFH h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    text-align: center;
}

.form2_formContainer__1IKFH h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #015275;
    text-align: right;
}

.form2_formContainer__1IKFH h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #015275;
    padding-bottom: 10px;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 input:-ms-input-placeholder {
    color: #0085bec7;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 input::placeholder {
    color: #0085bec7;
}

.form2_formContainer__1IKFH .form2_form__2jbw8 input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form2_formContainer__1IKFH button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.form2_rulesContainer__KG4W9 {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.form2_rulesContainer__KG4W9 p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #015275;
}

.form2_rulesContainer__KG4W9 .form2_rulesLink__HAODk {
    margin: 0 4px;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form2_usePassword__RMdmI {
    width: 100%;
    padding: 10px 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form2_usePassword__RMdmI p {
    margin-left: 4px;
}

.form2_timerContainer__3VMZH {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #015275;
}

.form2_timerContainer__3VMZH .form2_timer__3-x2S {
    margin-left: 4px;
}

.form2_timerContainer__3VMZH .form2_ResendCode__HqAZ_ {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .form2_formContainer__1IKFH {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.form3_formContainer__zrIlc {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.form3_back__VtALS {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.form3_formContainer__zrIlc h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    text-align: center;
}

.form3_formContainer__zrIlc h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #015275;
    text-align: right;
}

.form3_formContainer__zrIlc form {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.form3_formContainer__zrIlc form .form3_showPass__2JqMF {
    position: absolute;
    top: 20px;
    left: 24px;
    cursor: pointer;
    z-index: 3;
    color: #015275;
}

.form3_formContainer__zrIlc form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    padding-left: 20px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form3_formContainer__zrIlc form input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form3_formContainer__zrIlc form input:-ms-input-placeholder {
    color: #0085bec7;
}

.form3_formContainer__zrIlc form input::placeholder {
    color: #0085bec7;
}

.form3_formContainer__zrIlc form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form3_formContainer__zrIlc button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.form3_rulesContainer__1exLw {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.form3_rulesContainer__1exLw p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #015275;
}

.form3_rulesContainer__1exLw .form3_rulesLink__2LdG5 {
    margin: 0 4px;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form3_forgetPass__2kaM4 {
    width: 100%;
    padding: 10px 20px;
    padding-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form3_forgetPass__2kaM4 p {
    margin-left: 4px;
}

.form3_timerContainer__2xJDf {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #015275;
}

.form3_timerContainer__2xJDf .form3_timer__HkCoo {
    margin-left: 4px;
}

.form3_timerContainer__2xJDf .form3_ResendCode__2-Kd1 {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .form3_formContainer__zrIlc {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.form4_formContainer__1W4j6 {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.form4_back__2bx9Z {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.form4_formContainer__1W4j6 h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    margin-bottom: 20px;
    text-align: center;
}

.form4_formContainer__1W4j6 h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: large;
    color: #015275;
}

.form4_formContainer__1W4j6 form {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form4_formContainer__1W4j6 form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form4_formContainer__1W4j6 form input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form4_formContainer__1W4j6 form input:-ms-input-placeholder {
    color: #0085bec7;
}

.form4_formContainer__1W4j6 form input::placeholder {
    color: #0085bec7;
}

.form4_formContainer__1W4j6 form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form4_formContainer__1W4j6 button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

@-moz-document url-prefix() {
    .form4_formContainer__1W4j6 {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.form5_formContainer__rnwDy {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.form5_back__zb8aQ {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.form5_formContainer__rnwDy h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    text-align: center;
}

.form5_formContainer__rnwDy h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #015275;
    text-align: right;
}

.form5_formContainer__rnwDy h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #015275;
    padding-bottom: 10px;
}

.form5_formContainer__rnwDy form {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form5_formContainer__rnwDy form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form5_formContainer__rnwDy form input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form5_formContainer__rnwDy form input:-ms-input-placeholder {
    color: #0085bec7;
}

.form5_formContainer__rnwDy form input::placeholder {
    color: #0085bec7;
}

.form5_formContainer__rnwDy form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form5_formContainer__rnwDy button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.form5_timerContainer__2bjqp {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #015275;
}

.form5_timerContainer__2bjqp .form5_timer__9k7-d {
    margin-left: 4px;
}

.form5_timerContainer__2bjqp .form5_ResendCode__1nnU6 {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .form5_formContainer__rnwDy {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.form6_formContainer__1ZetJ {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.form6_back__gB_mc {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.form6_formContainer__1ZetJ h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    text-align: center;
}

.form6_formContainer__1ZetJ h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #015275;
    text-align: right;
}

.form6_formContainer__1ZetJ h3 {
    width: 100%;
    padding: 10px 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: large;
    color: #015275;
    text-align: right;
}

.form6_formContainer__1ZetJ form {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.form6_formContainer__1ZetJ form .form6_showPass__2tYdQ {
    position: absolute;
    top: 20px;
    left: 24px;
    cursor: pointer;
    z-index: 3;
    color: #015275;
}

.form6_formContainer__1ZetJ form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    padding-left: 20px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.form6_formContainer__1ZetJ form input::-webkit-input-placeholder {
    color: #0085bec7;
}

.form6_formContainer__1ZetJ form input:-ms-input-placeholder {
    color: #0085bec7;
}

.form6_formContainer__1ZetJ form input::placeholder {
    color: #0085bec7;
}

.form6_formContainer__1ZetJ form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.form6_formContainer__1ZetJ button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.form6_rulesContainer__1aSPC {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.form6_rulesContainer__1aSPC p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #015275;
}

.form6_rulesContainer__1aSPC .form6_rulesLink__2PwLY {
    margin: 0 4px;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form6_forgetPass__19Pgr {
    width: 100%;
    padding: 10px 20px;
    padding-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.form6_forgetPass__19Pgr p {
    margin-left: 4px;
}

.form6_timerContainer__36qNe {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #015275;
}

.form6_timerContainer__36qNe .form6_timer__t7dve {
    margin-left: 4px;
}

.form6_timerContainer__36qNe .form6_ResendCode__1-h1P {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .form6_formContainer__1ZetJ {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.login_Container1__27qaq {
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.login_LoginContainer__3NFeL {
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    min-height: 800px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.page1_Container1__1a1Ve {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.page1_Container1__1a1Ve .page1_ContainerOver__4fe-b {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.page1_Container1__1a1Ve .page1_ContainerOver__4fe-b .page1_Container2__S87fq {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.page1_Container1__1a1Ve .page1_ContainerOver__4fe-b .page1_Container2__S87fq ::-webkit-scrollbar {
    display: none;
}

.page1_headerContain__oDEs- {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.page1_headerContain__oDEs- .page1_right__2sP0t {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.page1_headerContain__oDEs- .page1_left__3hFpy {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.page1_contentContain__1sgFB {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.page1_Page1Item__lwrGq {
    width: 270px;
    height: 270px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    cursor: pointer;
    transition: all 0.3s;
}

.page1_Page1Item__lwrGq:hover {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}
.curentTime_CurentTimeContainer__2HJ1M {
    width: 150px;
    height: 80px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
}

.curentTime_CurentTimeContainer__2HJ1M .curentTime_showTimeCon__1_wAm {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.curentTime_CurentTimeContainer__2HJ1M .curentTime_showTimeCon__1_wAm .curentTime_dayOfWeek__1cOEH {
    background-color: #00b4d8;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
    margin-left: 5px;
}

.curentTime_CurentTimeContainer__2HJ1M .curentTime_showTimeCon__1_wAm .curentTime_timeNow__3hnjk {
    background-color: #90e0ef;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
}

.curentTime_CurentTimeContainer__2HJ1M .curentTime_showDataCon__3fvXM {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.curentTime_CurentTimeContainer__2HJ1M .curentTime_showDataCon__3fvXM>div {
    padding: 5px;
    margin-left: 3px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-size: small;
    font-weight: 500;
    background-color: white;
    color: #333333;
}
.userDetails_userDetailsContainer__39Q8m {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
}

.userDetails_userDetailsContainer__39Q8m .userDetails_userIconCon__2rJoI {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    /* border: 2px solid #d84b74; */
    border: 2px solid #da677f;
    color: #333;
    font-size: 25px;
    cursor: pointer;
}

.userDetails_profilePic__3VRuz {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.userDetails_DetailsPan__M1h_z {
    position: absolute;
    z-index: 5;
    top: 105%;
    min-height: 500px;
    min-width: 300px;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
}

.userDetails_DetailsPan__M1h_z .userDetails_detailsItem__2Syo5 {
    width: 95%;
    padding: 7px 10px;
    background-color: #ade8f4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.userDetails_DetailsPan__M1h_z .userDetails_detailsItem__2Syo5 .userDetails_label__3bT42 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    color: #333;
}

.userDetails_DetailsPan__M1h_z .userDetails_detailsItem__2Syo5 .userDetails_data__3AmI3 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: medium;
    color: #333;
}

.userDetails_accountDetailsCon__R6eqf {
    width: 90%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.userDetails_accountItem__4nL60 {
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    border: 2px solid #023e8a;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.userDetails_accountItem__4nL60 .userDetails_label__3bT42 {
    width: 100%;
    height: 30%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: x-small;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userDetails_accountItem__4nL60 .userDetails_data__3AmI3 {
    width: 100%;
    height: 70%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userDetails_exitBtn__2cceP {
    width: 50%;
    height: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d6396f;
    margin-top: 25px;
    cursor: pointer;
}
.dailyText_dailyTextContainer__12e2D {
    width: 90%;
    height: 80px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #333;
    text-align: center;
    line-height: 0.8cm;
}
.pageKey_pageKeyContainer__3IVc5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.pageKey_keyPageItem__3_11w {
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    color: #333;
    padding: 10px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all 0.3s;
}

.pageKey_keyPageItem__3_11w:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.page2_Container1__3TdAY {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.page2_Container1__3TdAY .page2_ContainerOver__2GB3N {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.page2_Container1__3TdAY .page2_ContainerOver__2GB3N .page2_Container2__2LzLg {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.page2_Container1__3TdAY .page2_ContainerOver__2GB3N .page2_Container2__2LzLg ::-webkit-scrollbar {
    display: none;
}

.page2_headerContain__3gb6_ {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.page2_headerContain__3gb6_ .page2_right__hdxVv {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.page2_headerContain__3gb6_ .page2_left__C7-pl {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.page2_contentContain__14-7s {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.page2_contentLeft__3oefK {
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    /* cursor: pointer; */
    transition: all 0.3s;
    padding: 30px;
}

.page2_contentLeft__3oefK h1 {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: x-large;
    margin-bottom: 25px;
}

.page2_contentRight__17kwE {
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.page2_Page2Item__2icuE {
    width: 250px;
    height: 250px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 45px;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    color: #333;
    margin: 15px;
    cursor: pointer;
    transition: all 0.3s;
}

.page2_Page2Item__2icuE:hover {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}
.page3_Container1__3iHvw {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.page3_Container1__3iHvw .page3_ContainerOver__1JDnV {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.page3_Container1__3iHvw .page3_ContainerOver__1JDnV .page3_Container2__1umNc {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.page3_Container1__3iHvw .page3_ContainerOver__1JDnV .page3_Container2__1umNc ::-webkit-scrollbar {
    display: none;
}

.page3_headerContain__1BWOX {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.page3_headerContain__1BWOX .page3_right__gP4xX {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.page3_headerContain__1BWOX .page3_left__1XBt0 {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.page3_contentContain__2P7bo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.page3_contentLeft__S8zm3 {
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    /* cursor: pointer; */
    transition: all 0.3s;
    padding: 30px;
}

.page3_contentLeft__S8zm3 h1 {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: xx-large;
    margin-bottom: 25px;
    background-color: #00b4d8;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
}

.page3_contentRight__2u9r4 {
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #00b4d8;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid #00b4d891;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.page3_mainLabel__1u40S {
    width: 100%;
    padding: 15px;
    border-bottom: 0.5mm solid #56cfe1;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: white;
    padding-top: 0;
}

.page3_selectContain__12Mf2 {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.page3_selectContain__12Mf2 .page3_item2__1CUOW {
    width: 50%;
    height: 30px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
}

.page3_numberInput__2Ohd1 {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
    outline: none;
    border: none;
    text-align: center;
}

.page3_selectContain__12Mf2 select datalist {
    width: 50%;
}


/* ---------- */

.page3_taggoleContain__ofA_2 {
    width: 40px;
    height: 14px;
    border: 0.1mm solid rgb(83, 83, 83);
    position: relative;
    border-radius: 20px;
    margin-left: 10px;
}

.page3_taggoleContain__ofA_2 .page3_buttonOfTaggole__1n-TL {
    width: 17px;
    height: 17px;
    top: -2px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    position: absolute;
    transition: all 0.5ms;
    z-index: 3;
    cursor: pointer;
}

.page3_taggoleActive__1NP_1 {
    left: 0;
    background-color: #80ffdb;
}

.page3_taggoleDeActive__1oSl5 {
    right: 0;
    background-color: rgb(94, 93, 93);
}

.page3_contentRight__2u9r4 button {
    width: 50%;
    background-color: #80ffdb;
    color: #333;
    font-weight: 800;
    font-size: large;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.selectList_selectListContain__CE6SU {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 500px;
    min-width: 70px;
    min-height: 30px;
    font-family: "vazir", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
}

.selectList_inputContain__3zwGL {
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-family: "vazir", sans-serif;
    padding: 2px 7px;
    cursor: pointer;
}

.selectList_selectedLabel__32gjL {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 500;
    cursor: pointer;
    color: #333;
}

.selectList_selectedLabel__32gjL>p {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectList_downIcon__1Z0re {
    width: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #333;
    font-family: "vazir", sans-serif;
}

.selectList_listContain__3HLOd {
    width: 100%;
    max-height: 150px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: white;
    font-family: "vazir", sans-serif;
    position: absolute;
    top: calc(100% + 6px);
    z-index: 100;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

.selectList_listContain__3HLOd ::-webkit-scrollbar {
    display: none;
}

.selectList_listItem__2vtwE {
    width: 100%;
    height: 30px;
    min-height: 30px;
    border-radius: 7px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    text-align: center;
    background-color: white;
    transition: all 0.2s;
    padding-top: 0px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 500;
}

.selectList_listItem__2vtwE:hover {
    background-color: rgb(119, 126, 128);
}
.page4_Container1__2AKk3 {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.page4_Container1__2AKk3 .page4_ContainerOver__3T7MJ {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.page4_Container1__2AKk3 .page4_ContainerOver__3T7MJ .page4_Container2__3bxSL {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.page4_Container1__2AKk3 .page4_ContainerOver__3T7MJ .page4_Container2__3bxSL ::-webkit-scrollbar {
    display: none;
}

.page4_headerContain__2Ev0S {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.page4_headerContain__2Ev0S .page4_right__2ZTpT {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.page4_headerContain__2Ev0S .page4_left__2mvqR {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.page4_contentContain__b5lQa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.page4_contentLeft__2VEDD {
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    /* cursor: pointer; */
    transition: all 0.3s;
    padding: 30px;
}

.page4_contentLeft__2VEDD h1 {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: xx-large;
    margin-bottom: 25px;
    background-color: #e46b8b;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
}

.page4_contentRight__2CPC8 {
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #e46b8b;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid #e46b8bc0;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.page4_mainLabel__310Vl {
    width: 100%;
    padding: 15px;
    border-bottom: 0.5mm solid #ff769b;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: white;
    padding-top: 0;
}

.page4_contentRight__2CPC8 button {
    width: 80%;
    background-color: hsl(349, 100%, 85%);
    color: #333;
    font-weight: 800;
    font-size: medium;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    margin: 20px 0px;
}

.page4_contentRight__2CPC8 .page4_selectedCourse__1Vlih {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.page4_contentRight__2CPC8 .page4_selectedCourse__1Vlih::-webkit-scrollbar {
    display: none;
}

.page4_startPishAzmoon__1mDmg {
    width: 100px;
    height: 60px;
    border-radius: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-bottom: 0.5mm solid #ff769b;
    background-color: #e46b8b;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.page4_selectContain__2U8Ec {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}


/* ---------- */

.page4_taggoleContain__3ynRE {
    width: 40px;
    height: 14px;
    border: 0.1mm solid rgb(83, 83, 83);
    position: relative;
    border-radius: 20px;
    margin-left: 10px;
}

.page4_taggoleContain__3ynRE .page4_buttonOfTaggole__3722h {
    width: 17px;
    height: 17px;
    top: -2px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    position: absolute;
    transition: all 0.5ms;
    z-index: 3;
    cursor: pointer;
}

.page4_taggoleActive__1pwdn {
    left: 0;
    background-color: #ff769b;
}

.page4_taggoleDeActive__2l34O {
    right: 0;
    background-color: rgb(94, 93, 93);
}

.page4_selectContain__2U8Ec .page4_item2__2Z8q2 {
    width: 50%;
    height: 30px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
}

.page4_numberInput__k95dy {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
    outline: none;
    border: none;
    text-align: center;
}
.pishAzmoonSelect_backDark__3Oc3G {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.377);
}

.pishAzmoonSelect_payehCon__aagqu {
    width: 25vw;
    max-width: 150px;
    min-width: 80px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    padding: 25px 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-left: 15px;
    position: relative;
}

.pishAzmoonSelect_payehCon__aagqu::-webkit-scrollbar {
    display: none;
}

.pishAzmoonSelect_payehLab__27mKb {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #e46b8b;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pishAzmoonSelect_payehItem__225QL {
    width: 90%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    border-radius: 15px;
    cursor: pointer;
}

.pishAzmoonSelect_selectItem__3OS6H {
    border: 1px solid #80ffdb;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.pishAzmoonSelect_booksCon__2zqM7 {
    width: 30vw;
    max-width: 190px;
    min-width: 100px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.pishAzmoonSelect_conCon__2ywlM {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 25px 0px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.pishAzmoonSelect_conCon__2ywlM::-webkit-scrollbar {
    display: none;
}

.pishAzmoonSelect_booksCon__2zqM7 .pishAzmoonSelect_labelBook__38g0f {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: #e46b8b;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pishAzmoonSelect_bookItem__2UGfV {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: small;
    overflow: hidden;
}

.pishAzmoonSelect_addToPishAzmoon__2CxOi {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: white;
    border: 1mm solid #e46b8b;
    color: #e46b8b;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: calc(50vh - 37px);
}

.pishAzmoonSelect_mabhasCon__1Ze4- {
    width: 30vw;
    max-width: 190px;
    min-width: 100px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.pishAzmoonSelect_mabhasCon__1Ze4- .pishAzmoonSelect_labelmabhas__noFYg {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #e46b8b;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pishAzmoonSelect_mabhasItem__BZ9uJ {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: small;
    overflow: hidden;
}

.pishAzmoonSelect_numberCon__wkh2M {
    width: 30vw;
    max-width: 100px;
    min-width: 60px;
    height: 40vh;
    max-height: 600px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    margin-top: 25vh;
    margin-left: 15px;
    position: relative;
}

.pishAzmoonSelect_numberCon__wkh2M .pishAzmoonSelect_labelnumber__2d6o- {
    width: 100%;
    height: 35px;
    border-radius: 16px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #e46b8b;
    color: white;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    position: absolute;
    top: -45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pishAzmoonSelect_numberItem__7fzde {
    width: 90%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    color: #333;
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: small;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
}
.selectedItem_selectedCourse__2cBXv {
    width: 80%;
    height: 65px;
    min-height: 65px;
    border-radius: 7px;
    background-color: #ffb3c1;
    color: #333;
    font-family: "vazir";
    font-weight: 600;
    font-size: small;
    position: relative;
    display: flex;
    margin-left: 17px;
    margin-bottom: 5px;
}

.selectedItem_number__cb3lg {
    width: 15px;
    height: 65px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    right: -17px;
    top: 0;
}

.selectedItem_editeBtn__1y6Rs {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    left: -32px;
    top: 0;
    cursor: pointer;
}

.selectedItem_delBtn__2qUn2 {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb3c1;
    color: #333;
    position: absolute;
    left: -32px;
    bottom: 0;
    cursor: pointer;
}

.selectedItem_detailsCon__FHy10 {
    width: 100%;
    height: 100%;
    padding: 5px;
    text-align: justify;
    line-height: 0.6cm;
    font-family: "vazir";
    font-weight: 500;
    font-size: x-small;
    color: rgb(63, 63, 63);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.selectedItem_conCon__1L93_::-webkit-scrollbar {
    display: none;
}
.loading_Container__1Zden {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

@-moz-document url-prefix() {
    .loading_Container__1Zden {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.alert_Container__1ijcW {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert_alertContainer__3xriC {
    width: 300px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.alert_alertContainer__3xriC .alert_text__mVpSd {
    width: 100%;
    padding-bottom: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
}

.alert_alertContainer__3xriC .alert_button__1BQH5 {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #99d98c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

@-moz-document url-prefix() {
    .alert_Container__1ijcW {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.azmoon_azmoonCon__57pRZ {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    padding: 7px;
}

.azmoon_azmoonCon__57pRZ .azmoon_part1Con__3cy6s {
    width: 20%;
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
}

.azmoon_azmoonCon__57pRZ .azmoon_part1Con__3cy6s::-webkit-scrollbar {
    display: none;
}

.azmoon_azmoonCon__57pRZ .azmoon_part2Con__1_L8u {
    width: 60%;
    max-width: 900px;
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
    position: relative;
}

.azmoon_azmoonCon__57pRZ .azmoon_part2Con__1_L8u::-webkit-scrollbar {
    display: none;
}

.azmoon_azmoonCon__57pRZ .azmoon_part3Con__1BxKT {
    width: 20%;
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
}

.azmoon_azmoonCon__57pRZ .azmoon_part3Con__1BxKT::-webkit-scrollbar {
    display: none;
}


/* ----------- */

.azmoon_endExamBtn__2eR4w {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    background-color: #00e676;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
}

.azmoon_discardExamBtn__2kY3G {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    border-radius: 15px;
    background-color: #ff1744;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
}


/* ========================= */

.azmoon_nextQuestion__HWQnT {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(51, 51, 51, 0.503);
    color: white;
    top: calc(50% - 25px);
    right: 2px;
    z-index: 3;
    cursor: pointer;
    border-radius: 5px;
}

.azmoon_prevQuestion__3T8TU {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(51, 51, 51, 0.503);
    color: white;
    top: calc(50% - 25px);
    left: 2px;
    z-index: 3;
    cursor: pointer;
    border-radius: 5px;
}
 .box2_detailsItem2__3XxdL {
     width: 100%;
     background-color: white;
     border-radius: 15px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 20px 7px;
     margin-bottom: 10px;
     font-family: "vazir", sans-serif;
 }
 
 .box2_detailsItem2__3XxdL .box2_studentContain__19PcZ {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
 }
 
 .box2_detailsItem2__3XxdL .box2_studentContain__19PcZ .box2_content__kNcZF {
     font-weight: 700;
     font-size: x-large;
 }
 
 .box2_detailsItem2__3XxdL .box2_schoollevelContain__28BtO {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
 }
 
 .box2_detailsItem2__3XxdL .box2_schoollevelContain__28BtO .box2_label__uojWD {
     font-weight: 700;
     margin-left: 4px;
 }
.box1_CurentTimeContainer__fOWTG {
    width: 100%;
    height: 80px;
    min-height: 80px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    margin-bottom: 10px;
}

.box1_CurentTimeContainer__fOWTG .box1_showTimeCon__1pHFk {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.box1_CurentTimeContainer__fOWTG .box1_showTimeCon__1pHFk .box1_dayOfWeek__1g9fW {
    background-color: #00b4d8;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
    margin-left: 5px;
}

.box1_CurentTimeContainer__fOWTG .box1_showTimeCon__1pHFk .box1_timeNow__1jEKy {
    background-color: #90e0ef;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
}

.box1_CurentTimeContainer__fOWTG .box1_showDataCon__3tFLc {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.box1_CurentTimeContainer__fOWTG .box1_showDataCon__3tFLc>div {
    padding: 5px;
    margin-left: 3px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-size: small;
    font-weight: 500;
    background-color: white;
    color: #333333;
}
.box3a_detailsItem3__dWwr7 {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
}

.box3a_detailsItem3__dWwr7 .box3a_kindazmunContain__3PvdM {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.box3a_detailsItem3__dWwr7 .box3a_kindazmunContain__3PvdM .box3a_content__3GiEe {
    width: 90%;
    font-weight: 900;
    font-size: x-large;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.box3a_detailsItem3__dWwr7 .box3a_detailsazmunContain__1K4zu {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.box3a_detailsItem3__dWwr7 .box3a_detailsazmunContain__1K4zu .box3a_label__1KHaH {
    font-weight: 700;
    margin-left: 4px;
}

.box3a_detailsItem3__dWwr7 .box3a_timeazmunContain__1vs1L {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.box3b_box3bCon__qeBAE {
    width: 100%;
    height: 270px;
    max-height: 270px;
    min-height: 270px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.box3b_box3bCon__qeBAE::-webkit-scrollbar {
    display: none;
}

.box3b_mainLabel__2NVHr {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    margin-bottom: 5px;
}

.box3b_eachCourseCon__3LcBH {
    width: 95%;
    height: 100px;
    min-height: 100px;
    background-color: #90e0ef;
    border-radius: 5px;
    margin-bottom: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.box3b_eachCourseCon__3LcBH::-webkit-scrollbar {
    display: none;
}

.box3b_detailsazmunContain__JlDAG {
    width: 95%;
    padding: 3px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: small;
}

.box3b_detailsazmunContain__JlDAG .box3b_label__3mVCt {
    font-weight: 700;
}

.box3b_mabahesCon__1J6_B {
    width: 95%;
    padding: 3px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
}

.box3b_mabahesCon__1J6_B .box3b_label__3mVCt {
    margin-bottom: 4px;
    font-weight: 700;
}

.box3b_mabahesCon__1J6_B .box3b_content__2x0FC {
    width: 95%;
    text-align: center;
    font-size: x-small;
}
 .box4_detailsItem2__3U_-u {
     width: 100%;
     background-color: white;
     border-radius: 15px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 20px 7px;
     margin-bottom: 10px;
     font-family: "vazir", sans-serif;
 }
 
 .box4_detailsItem2__3U_-u .box4_studentContain__PhgYu {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
 }
 
 .box4_detailsItem2__3U_-u .box4_studentContain__PhgYu .box4_content__g-u8s {
     font-weight: 700;
     font-size: x-large;
 }
 
 .box4_detailsItem2__3U_-u .box4_schoollevelContain__2rEpm {
     width: 100%;
     height: 30px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     margin-bottom: 4px;
 }
 
 .box4_detailsItem2__3U_-u .box4_schoollevelContain__2rEpm .box4_label__Y0VXc {
     font-weight: 700;
     margin-left: 4px;
 }
.box5_detailsItem3__2zLHu {
    width: 100%;
    height: 80px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
}

.box5_Qcolor1__2eTC1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.box5_Qcolor1__2eTC1 .box5_color__2oGYM {
    width: 15px;
    height: 15px;
    background-color: #8bc34a;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.box5_Qcolor1__2eTC1 .box5_text__2r1xr {
    font-weight: 500;
    font-size: x-small;
}

.box5_Qcolor2__3LBoG {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.box5_Qcolor2__3LBoG .box5_color__2oGYM {
    width: 15px;
    height: 15px;
    background-color: #e53935;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.box5_Qcolor2__3LBoG .box5_text__2r1xr {
    font-weight: 500;
    font-size: x-small;
}

.box5_Qcolor3__Phe56 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.box5_Qcolor3__Phe56 .box5_color__2oGYM {
    width: 15px;
    height: 15px;
    background-color: #ffeb3b;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.box5_Qcolor3__Phe56 .box5_text__2r1xr {
    font-weight: 500;
    font-size: x-small;
}
 .box6_detailsItem2__2-liw {
     width: 100%;
     height: 335px;
     background-color: white;
     border-radius: 15px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
     align-items: flex-start;
     justify-content: flex-start;
     padding: 10px 5px;
     margin-bottom: 10px;
     overflow-x: auto;
     -ms-overflow-style: none;
     scrollbar-width: none;
     font-family: "vazir", sans-serif;
 }
 
 .box6_detailsItem2__2-liw::-webkit-scrollbar {
     display: none;
 }
 
 .box6_sumeryAnswer__1rEQ- {
     padding: 3px;
     border-radius: 7px;
     background-color: #cfd8dc;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     margin-bottom: 3px;
     margin-left: 3px;
     cursor: pointer;
 }
 
 .box6_sumeryAnswerActive__3c0-3 {
     padding: 3px;
     border-radius: 7px;
     background-color: #cfd8dc;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     margin-bottom: 3px;
     margin-left: 3px;
     cursor: pointer;
     border: 0.4mm solid black;
 }
 
 .box6_Qnumber__10NGn {
     margin-left: 3px;
     padding-top: 3px;
     font-size: xx-small;
 }
 
 .box6_Qa1__K_Dpm {
     border-radius: 5px;
     background-color: white;
     border: 0.3mm solid black;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 100;
     font-size: 6px;
     margin-left: 3px;
     width: 15px;
     padding-top: 2px;
 }
 
 .box6_Qa1An__1VrVg {
     border-radius: 5px;
     background-color: rgb(53, 53, 53);
     border: 0.3mm solid black;
     color: white;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 100;
     font-size: 6px;
     margin-left: 3px;
     width: 15px;
     padding-top: 2px;
 }
.box7_question__1oP22 {
    width: 90%;
    min-height: 400px;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #cfd8dc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    font-family: "vazir", sans-serif;
}

.box7_question__1oP22 img {
    width: auto;
    max-width: 90%;
    height: auto;
}

.box7_question__1oP22 .box7_statusBtn__1lMQk {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 7px;
    background-color: #80deea;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.box7_question__1oP22 .box7_statusBtn__1lMQk .box7_byt__3eRpr {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.box7_question__1oP22 .box7_statusBtn__1lMQk .box7_yellow__2trCH {
    background-color: #ffeb3b;
}

.box7_question__1oP22 .box7_statusBtn__1lMQk .box7_red__1TG9f {
    background-color: #e53935;
}

.box7_question__1oP22 .box7_statusBtn__1lMQk .box7_green__3iL2V {
    background-color: #8bc34a;
}

.box7_question__1oP22 .box7_number__2kqQi {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: x-large;
}

.box7_question__1oP22 .box7_text__27sY0 {
    font-family: "vazir", sans-serif !important;
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: large;
    background-color: #fafafa;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.box7_question__1oP22 .box7_text__27sY0 * {
    text-align: justify;
    width: auto !important;
    max-width: 90% !important;
}

.box7_question__1oP22 .box7_text__27sY0 div {
    width: 97% !important;
}

.box7_question__1oP22 .box7_text__27sY0 span {
    font-family: "vazir", sans-serif !important;
}

.box7_question__1oP22 .box7_text__27sY0 p {
    font-family: "vazir", sans-serif !important;
}

.box7_question__1oP22 .box7_text__27sY0 div {
    width: 100%;
    padding: 7px 20px;
    text-align: justify;
    font-family: "vazir", sans-serif !important;
}

.box7_question__1oP22 .box7_answer__3MYlQ {
    width: 95%;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    font-weight: 700;
    font-size: large;
    background-color: #fafafa;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}

.box7_question__1oP22 .box7_source__2YZjJ {
    width: 95%;
    margin: 5px auto;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    font-weight: 500;
    font-size: medium;
    background-color: transparent;
    border-radius: 15px;
    cursor: pointer;
    color: #616161;
}

.box7_question__1oP22 .box7_answer__3MYlQ input {
    margin-left: 7px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 4px;
    cursor: pointer;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g1__3GxcS {
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g1__3GxcS span {
    font-family: "vazir", sans-serif !important;
    margin-left: 3px;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g2__MFiQo {
    font-weight: 300;
    text-align: justify;
    padding: 3px;
    width: 75%;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g2__MFiQo * {
    font-weight: 300;
    text-align: justify;
    padding: 3px;
    width: auto !important;
    max-width: 90% !important;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g2__MFiQo div {
    width: 97% !important;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g2__MFiQo span {
    font-family: "vazir", sans-serif !important;
    font-weight: 5300;
    margin-left: 3px;
}

.box7_question__1oP22 .box7_answer__3MYlQ div.box7_g2__MFiQo p {
    font-family: "vazir", sans-serif !important;
}

.box7_question__1oP22 .box7_answer__3MYlQ div {
    margin-left: 7px;
}
.myPejvok_Container__1exeL {
    width: 100vw;
    height: 100vh;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 20px 0px;
}

.myPejvok_headerContain__XNzzM {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.myPejvok_headerContain__XNzzM .myPejvok_right__1GjEs {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.myPejvok_headerContain__XNzzM .myPejvok_left__qmfo0 {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.myPejvok_contentContain__3Upzp {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.myPejvok_mainContent__2nkqx {
    width: 70%;
    max-width: 1280px;
    min-width: 310px;
    height: 100%;
    min-height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

.myPejvok_mainContent__2nkqx ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .myPejvok_mainContent__2nkqx {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.myPejvok_menuContain__1tO8O {
    width: 20%;
    max-width: 1280px;
    min-width: 310px;
    height: 100%;
    min-height: 500px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.myPejvok_menuContain__1tO8O .myPejvok_menuBtnD__1fdtm {
    width: 110px;
    height: 60px;
    border-radius: 7px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 2px solid #077bae;
    transition: all 0.3s;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
}

@-moz-document url-prefix() {
    .myPejvok_menuContain__1tO8O .myPejvok_menuBtnD__1fdtm {
        background: rgba(255, 255, 255, 0.884);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 2px solid #14097b;
    }
}

.myPejvok_menuContain__1tO8O .myPejvok_menuBtnA__GltoS {
    width: 120px;
    height: 70px;
    border-radius: 7px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 4px solid #e13e76;
    transition: all 0.3s;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
}

@-moz-document url-prefix() {
    .myPejvok_menuContain__1tO8O .myPejvok_menuBtnA__GltoS {
        background: rgba(255, 255, 255, 0.884);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 4px solid #e13e76;
    }
}

.myPejvok_menuContain__1tO8O .myPejvok_menuBtnD__1fdtm:hover {
    -webkit-transform: scale(1.1) translate(-10px, 0px);
            transform: scale(1.1) translate(-10px, 0px);
}
.item1_container__2B4p4 {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir", sans-serif;
}

.item1_itemsContain__3ZTHc {
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    background-color: #ade8f4;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.item1_itemsContain22__1LoIX {
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.item1_noPic__3GHzf {
    width: 70px;
    height: 70px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item1_profilePic__3MS0P {
    width: 70px;
    height: 70px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item1_editProfileBtn__1S55u {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e13e76;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    margin-right: 30px;
    cursor: pointer;
    color: white;
    font-weight: 900;
}

.item1_itemsContain__3ZTHc .item1_label__yZm9i {
    font-weight: 800;
    font-size: large;
    margin-left: 5px;
}

.item1_itemsContain__3ZTHc .item1_label__yZm9i {
    font-weight: 500;
    font-size: large;
}


/* ---------------------------- */
    .item3_container__1V1WV {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-family: "vazir", sans-serif;
        padding-bottom: 100px;
    }
    
    .item3_banerContainer__2vCVq {
        width: 70%;
        min-width: 280px;
        max-width: 500px;
        height: 30%;
        min-height: 100px;
        max-height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
    }
    
    .item3_banerPic__2B1wY {
        width: 100%;
        height: 100%;
        border-radius: 7px;
    }
    
    .item3_acountContainer__2IZsT {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
    }
    
    .item3_acountContainer__2IZsT .item3_itemsContain__3FqiW {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin: 10px 0px;
    }
    
    .item3_azmunakContainer__35K-3 {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .item3_azmunakContainer__35K-3 h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #fe5c4d;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .item3_azmunakContainer__35K-3 .item3_itemsContain__3FqiW {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .item3_azmunakContainer__35K-3 .item3_itemsContain__3FqiW .item3_content__1urBV {
        padding: 5px;
        border-radius: 5px;
        background-color: #fd867c;
    }
    
    .item3_pishazmunContainer__ohHIo {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .item3_pishazmunContainer__ohHIo h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #7678ff;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .item3_pishazmunContainer__ohHIo .item3_itemsContain__3FqiW {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .item3_pishazmunContainer__ohHIo .item3_itemsContain__3FqiW .item3_content__1urBV {
        padding: 5px;
        border-radius: 5px;
        background-color: #9fa0f8;
    }
    
    .item3_nightTestContainer__3JBfV {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .item3_nightTestContainer__3JBfV h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #3c7165;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .item3_nightTestContainer__3JBfV .item3_itemsContain__3FqiW {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .item3_nightTestContainer__3JBfV .item3_itemsContain__3FqiW .item3_content__1urBV {
        padding: 5px;
        border-radius: 5px;
        background-color: #678c84;
    }
    
    .item3_moshaverehContainer__93tlg {
        width: 210px;
        min-width: 210px;
        max-width: 210px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .item3_moshaverehContainer__93tlg h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #717171;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .item3_moshaverehContainer__93tlg .item3_itemsContain__3FqiW {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .item3_moshaverehContainer__93tlg .item3_itemsContain__3FqiW .item3_content__1urBV {
        padding: 5px;
        border-radius: 5px;
        background-color: #a3a3a3;
    }
    
    .item3_itemsContain__3FqiW .item3_label__1jYr0 {
        font-weight: 800;
        font-size: large;
        margin-left: 5px;
    }
    
    .item3_itemsContain__3FqiW .item3_label__1jYr0 {
        font-weight: 500;
        font-size: large;
    }
    
    .item3_buyAccount__25G_0 {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .item3_buyAccountBtn__3J_sy {
        width: 150px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1a0f91;
        color: white;
        font-weight: 900;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    /* ---------------------------- */
    
    .item3_noAcount__95BhP {
        width: 80%;
        text-align: center;
        font-family: "vazir", sans-serif;
        font-weight: 900;
        font-size: x-large;
        color: #333;
    }
    .item4_container__2kFGH {
        width: 100%;
        height: 100%;
        max-height: 400px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        padding-top: 40px;
        font-family: "vazir", sans-serif;
        padding-bottom: 30px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: scroll;
    }
    
    .item4_container__2kFGH ::-webkit-scrollbar {
        display: none;
    }
    
    .item4_columnCon__3EKny {
        width: 30%;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
    }
    
    .item4_label__1448D {
        width: 100%;
        text-align: center;
        margin: 20px 0px;
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: medium;
    }
    
    .item4_azmounContainer__1-BG7 {
        width: 100%;
        min-height: 30px;
        background-color: #ade8f4;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .item4_azmounContainer2__eZ3R8 {
        width: 100%;
        min-height: 30px;
        background-color: #ef9a9a;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .item4_azmounContainer3__12T-k {
        width: 100%;
        min-height: 30px;
        background-color: #80cbc4;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .item4_azmounLabel__3dxU6 {
        width: 90%;
        display: flex;
        margin: 10px 0px;
        padding: 0px 5px;
        justify-content: flex-start;
        align-items: center;
    }
    
    .item4_azmounLabel__3dxU6 h1 {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    .item4_otherDetails__1YMkp {
        width: 90%;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        margin: 10px 0px;
        background-color: white;
    }
    
    .item4_itemCon__1HAcM {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: x-small;
        margin-bottom: 10px;
    }
.pdfRender_section__1CJ1Q {
    width: 100%;
    font-family: "vazir", sans-serif;
    padding: 20px 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
}

.pdfRender_label__2WM2z {
    font-weight: 900;
    font-size: large;
    margin-left: 10px;
}

.pdfRender_content__1rXk3 {
    font-weight: 600;
    font-size: large;
}
.item2_container__1s5Ce {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(51, 51, 51, 0.53);
}

.item2_closeBtn__1Yquy {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #e13e76;
    cursor: pointer;
}

.item2_editForm__xjjhY {
    width: 310px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    position: relative;
    margin-top: 10vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    padding: 20px 0px;
}

.item2_editForm__xjjhY ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .item2_editForm__xjjhY {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.item2_item1__vyouC {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.item2_noPic__17omb {
    width: 80px;
    height: 80px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item2_profilePic__2vvPb {
    width: 80px;
    height: 80px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item2_profImg__3wYlc[type="file"] {
    display: none;
}

.item2_label__3_d5C {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    background-color: white;
}

.item2_label__3_d5C label {
    cursor: pointer;
}

.item2_item2__11on8 {
    width: 90%;
    height: 35px;
    min-height: 35px;
    position: relative;
}

.item2_checkValidate__2jxLl {
    position: absolute;
    top: 0;
    left: 0;
    width: 7%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.item2_item2__11on8 input {
    font-family: "vazir", sans-serif;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: #333;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    direction: rtl;
    text-align: center;
    font-weight: 800;
}

.item2_editForm__xjjhY>h2 {
    width: 100%;
    margin: 10px 0px;
    text-align: right;
    font-family: "vazir", sans-serif;
    font-size: medium;
    font-weight: 400;
    padding-right: 20px;
}

.item2_doneEdite__hM_Ku {
    width: 60%;
    height: 35px;
    min-height: 35px;
    margin-top: 20px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background: #e13e76;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    color: white;
    cursor: pointer;
}
.selectList_selectListContain__13oA2 {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 500px;
    min-width: 70px;
    min-height: 30px;
    font-family: "vazir", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
}

.selectList_inputContain__1yvml {
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-family: "vazir", sans-serif;
    padding: 2px 7px;
    cursor: pointer;
}

.selectList_selectedLabel__2GPu3 {
    width: 90%;
    height: 100%;
    text-align: center;
    padding-top: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 800;
    cursor: pointer;
    color: #333;
}

.selectList_downIcon__-p2Vu {
    width: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #333;
    font-family: "vazir", sans-serif;
}

.selectList_listContain__3RObp {
    width: 100%;
    max-height: 150px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: white;
    font-family: "vazir", sans-serif;
    position: absolute;
    top: calc(100% + 6px);
    z-index: 100;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

.selectList_listContain__3RObp ::-webkit-scrollbar {
    display: none;
}

.selectList_listItem__6pY95 {
    width: 100%;
    height: 30px;
    min-height: 30px;
    border-radius: 7px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    text-align: center;
    background-color: white;
    transition: all 0.2s;
    padding-top: 9px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 600;
}

.selectList_listItem__6pY95:hover {
    background-color: rgb(119, 126, 128);
}
div.nc {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    z-index: 404;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
}
.resultAzmoun_container__3YRVS {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    background-color: rgba(51, 51, 51, 0.53);
}

.resultAzmoun_closeBtn__1DxrV {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #e13e76;
    cursor: pointer;
}

.resultAzmoun_container2__3o3bd {
    width: 90vw;
    height: 500px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    position: relative;
    margin-top: 10vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    padding: 20px 20px;
}

.resultAzmoun_container2__3o3bd ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .resultAzmoun_container2__3o3bd {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.resultAzmoun_container2__3o3bd .resultAzmoun_easyDetails__tZ6r5 {
    width: 100%;
    height: 330px;
    min-height: 330px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    margin-bottom: 70px;
}

.resultAzmoun_itemCon__3qWui {
    width: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
}

.resultAzmoun_itemCon__3qWui .resultAzmoun_lab__3KLCe {
    font-weight: 700;
    color: #333;
    font-size: medium;
    margin-left: 5px;
}

.resultAzmoun_itemCon__3qWui .resultAzmoun_content__2Curk {
    font-weight: 400;
    color: #333;
    font-size: medium;
    margin-left: 5px;
}

.resultAzmoun_questionCon__2ckJC {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.resultAzmoun_topContainer__2g9st {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resultAzmoun_QuesLabelShoeBtn__2Zd8m {
    width: 80px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 7px;
    font-weight: 800;
    font-size: large;
    cursor: pointer;
    font-family: "vazir";
}

.resultAzmoun_questionLabelCon__2Wt8P {
    width: 100%;
    min-height: 40px;
    border-radius: 7px;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    margin: 3px 6px;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
}

.resultAzmoun_sendErrorOfQuestion__1noc1 {
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: 50px;
    height: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small;
    color: white;
    background-color: #333;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resultAzmoun_questionMabhas__1wG0F {
    width: 70%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: white;
    font-size: x-small;
    border-radius: 15px;
    margin-bottom: 5px;
}

.resultAzmoun_parCon__3S84F {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    margin-bottom: 5px;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_label__1LDf5 {
    width: 100%;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_label__1LDf5 p {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_label__1LDf5 sapn {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_label__1LDf5 * {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_content__2Curk {
    width: 100%;
    font-family: "vazir", sans-serif !important;
    font-weight: 400;
    font-size: medium;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: justify !important;
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 7px;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_content__2Curk p {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_content__2Curk sapn {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_parCon__3S84F .resultAzmoun_content__2Curk * {
    font-family: "vazir", sans-serif !important;
}

.resultAzmoun_printBtn__1dMKI {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7a7a7e;
    color: white;
    font-weight: 900;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-bottom: 30px;
}
.pdfRender_section__3aci2 {
    width: 100%;
    font-family: "vazir", sans-serif;
    padding: 20px 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
}

.pdfRender_label__1aRwh {
    font-weight: 900;
    font-size: large;
    margin-left: 10px;
}

.pdfRender_content__1tNlK {
    font-weight: 600;
    font-size: large;
}
.endAzmounAlert_Container__2uv9t {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.endAzmounAlert_alertContainer__27UsC {
    width: 300px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.endAzmounAlert_alertContainer__27UsC .endAzmounAlert_text__2yJVY {
    width: 100%;
    padding-bottom: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
}

.endAzmounAlert_alertContainer__27UsC .endAzmounAlert_button__319Fh {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #99d98c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

.endAzmounAlert_alertContainer__27UsC .endAzmounAlert_button2__WPpuL {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #cf6c6c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

.endAzmounAlert_btnContainer__MXqx_ {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@-moz-document url-prefix() {
    .endAzmounAlert_Container__2uv9t {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.otherPrint_Container__h5zWa {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.otherPrint_alertContainer__1rso9 {
    width: 300px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.otherPrint_alertContainer__1rso9 .otherPrint_text__1OlS4 {
    width: 100%;
    padding-bottom: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
}

button {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #9fa49f;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    margin-bottom: 5px;
    color: white;
}

button.otherPrint_botton__SitN7 {
    width: 60px;
    height: 27px;
    border-radius: 15px;
    background-color: #d48282;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
    margin-bottom: 5px;
    color: white;
}

@-moz-document url-prefix() {
    .otherPrint_Container__h5zWa {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.reportErorr_Container__3Tz5a {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.reportErorr_reportErorrContainer__IORes {
    width: 50%;
    min-width: 320px;
    min-height: 300px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 700;
    font-size: medium;
    position: relative;
}

.reportErorr_closeBtn__3UuNM {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #333;
    cursor: pointer;
}

.reportErorr_reportErorrContainer__IORes .reportErorr_title__q75KC {
    font-weight: 900;
    margin-bottom: 15px;
}

.reportErorr_textArea__3mCIC {
    width: 95%;
    height: 100px;
    border-radius: 15px;
    padding: 5px;
    font-family: "vazir", sans-serif;
    margin-bottom: 20px;
}

.reportErorr_submitErorr__1tPKe {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #333;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 20px;
    cursor: pointer;
}

.reportErorr_kindError__3s9qR {
    padding: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
}

.reportErorr_alertContainer__fFhGe {
    width: 300px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.reportErorr_alertContainer__fFhGe .reportErorr_text__3TRlf {
    width: 100%;
    padding-bottom: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
}

.reportErorr_alertContainer__fFhGe .reportErorr_button__1XyUF {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #99d98c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

@-moz-document url-prefix() {
    .reportErorr_Container__3Tz5a {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
