* {
    font-family: "vazir";
}

body {
    width: 100%;
    min-height: 100vh;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100vw;
    min-height: 100vh;
}

:root {
    --bgColor1: rgb(69, 90, 100);
    --bgColor2: #546e7a;
    --bgColor3: rgb(96, 125, 139);
    --bgColor4: rgb(120, 144, 156);
    --bgColor5: rgb(144, 164, 174);
    --bgColor6: rgb(176, 190, 197);
    --bgColor7: rgb(207, 216, 220);
    --bgColor8: rgb(236, 239, 241);
    --texColor1: #37474f;
    --texColor2: #546e7a;
    --texColor3: #eceff1;
    --boxShadow1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --boxShadow2: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    --boxShadow3: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    --CboxShadow1: rgba(84, 110, 122, 0.3) 0px 1px 2px 0px, rgba(84, 110, 122, 0.15) 0px 2px 6px 2px;
    --CboxShadow2: rgba(84, 110, 122, 0.16) 0px 3px 6px, rgba(84, 110, 122, 0.23) 0px 3px 6px;
    --CboxShadow3: rgba(84, 110, 122, 0.493) 0px 10px 20px, rgba(84, 110, 122, 0.445) 0px 6px 6px;
}

.coFlexC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coFlexS {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.roFlexC {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.roFlexE {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.roFlexB {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.roFlexS {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}


/* Hide scrollbar for IE, Edge and Firefox */

.hideScroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */

.hideScroll::-webkit-scrollbar {
    display: none;
}

.noneDisplay {
    display: none;
}

.vfxxlarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: xx-large;
}

.vfxxlarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-large;
}

.vfxxlarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
}

.vfxlarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: x-large;
}

.vfxlarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: x-large;
}

.vfxlarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: x-large;
}

.vflarge1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: large;
}

.vflarge2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: large;
}

.vflarge3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
}

.vfmedium1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: medium;
}

.vfmedium2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
}

.vfmedium3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: medium;
}

.vfsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: small;
}

.vfsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: small;
}

.vfsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: small;
}

.vfxsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: x-small;
}

.vfxsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: x-small;
}

.vfxsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: x-small;
}

.vfxxsmall1 {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: xx-small;
}

.vfxxsmall2 {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: xx-small;
}

.vfxxsmall3 {
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-small;
}

.textOver {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

input {
    direction: ltr;
}

.rmdp-container {
    font-family: "vazir", sans-serif;
    color: #333;
    width: 100%;
    height: 100%;
}

.rmdp-input {
    font-family: "vazir", sans-serif;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 0px;
}