.Container1 {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.Container1 .ContainerOver {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.Container1 .ContainerOver .Container2 {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.Container1 .ContainerOver .Container2 ::-webkit-scrollbar {
    display: none;
}

.headerContain {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.headerContain .right {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.headerContain .left {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.contentLeft {
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    /* cursor: pointer; */
    transition: all 0.3s;
    padding: 30px;
}

.contentLeft h1 {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: x-large;
    margin-bottom: 25px;
}

.contentRight {
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.Page2Item {
    width: 250px;
    height: 250px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 45px;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    color: #333;
    margin: 15px;
    cursor: pointer;
    transition: all 0.3s;
}

.Page2Item:hover {
    transform: scale(0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}