.pageKeyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.keyPageItem {
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    color: #333;
    padding: 10px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all 0.3s;
}

.keyPageItem:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}