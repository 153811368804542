.Container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.28);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.alertContainer {
    width: 300px;
    min-height: 50px;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.alertContainer .text {
    width: 100%;
    padding-bottom: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
}

.alertContainer .button {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #99d98c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

.alertContainer .button2 {
    width: 110px;
    height: 35px;
    border-radius: 15px;
    background-color: #cf6c6c;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    text-align: center;
    line-height: 0.7cm;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    cursor: pointer;
}

.btnContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@-moz-document url-prefix() {
    .Container {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.575);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}