.detailsItem3 {
    width: 100%;
    height: 80px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
}

.Qcolor1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.Qcolor1 .color {
    width: 15px;
    height: 15px;
    background-color: #8bc34a;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.Qcolor1 .text {
    font-weight: 500;
    font-size: x-small;
}

.Qcolor2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.Qcolor2 .color {
    width: 15px;
    height: 15px;
    background-color: #e53935;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.Qcolor2 .text {
    font-weight: 500;
    font-size: x-small;
}

.Qcolor3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: white;
    font-weight: 300;
    font-size: xx-small;
    margin-bottom: 3px;
}

.Qcolor3 .color {
    width: 15px;
    height: 15px;
    background-color: #ffeb3b;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-left: 20px;
}

.Qcolor3 .text {
    font-weight: 500;
    font-size: x-small;
}