.container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "vazir", sans-serif;
}

.itemsContain {
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    background-color: #ade8f4;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.itemsContain22 {
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.noPic {
    width: 70px;
    height: 70px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilePic {
    width: 70px;
    height: 70px;
    border: 2px solid rgb(66, 66, 66);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editProfileBtn {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e13e76;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 7px;
    margin-right: 30px;
    cursor: pointer;
    color: white;
    font-weight: 900;
}

.itemsContain .label {
    font-weight: 800;
    font-size: large;
    margin-left: 5px;
}

.itemsContain .label {
    font-weight: 500;
    font-size: large;
}


/* ---------------------------- */