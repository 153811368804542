.formContainer {
    width: 350px;
    height: 400px;
    min-width: 300px;
    min-height: 400px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
}

.back {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: #015275;
    font-size: 30px;
}

.formContainer h1 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: 45px;
    color: #0086be;
    text-align: center;
}

.formContainer h2 {
    width: 100%;
    padding: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #015275;
    text-align: right;
}

.formContainer h3 {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #015275;
    padding-bottom: 10px;
}

.formContainer .form {
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer .form input {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 10px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
    text-align: center;
    caret-color: #015275;
}

.formContainer .form input::placeholder {
    color: #0085bec7;
}

.formContainer .form input:focus {
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: medium;
    color: #015275;
}

.formContainer button {
    width: 87%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-family: "vazir", sans-serif;
    font-size: large;
    font-weight: 900;
    background-color: #015275;
    color: white;
    padding: 0;
}

.rulesContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.rulesContainer p {
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    white-space: nowrap;
    color: #015275;
}

.rulesContainer .rulesLink {
    margin: 0 4px;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.usePassword {
    width: 100%;
    padding: 10px 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #0086be;
    cursor: pointer;
}

.usePassword p {
    margin-left: 4px;
}

.timerContainer {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    color: #015275;
}

.timerContainer .timer {
    margin-left: 4px;
}

.timerContainer .ResendCode {
    cursor: pointer;
}

@-moz-document url-prefix() {
    .formContainer {
        width: 350px;
        height: 400px;
        min-width: 300px;
        min-height: 400px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}