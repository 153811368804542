    .container {
        width: 100%;
        height: 100%;
        max-height: 400px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        padding-top: 40px;
        font-family: "vazir", sans-serif;
        padding-bottom: 30px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: scroll;
    }
    
    .container ::-webkit-scrollbar {
        display: none;
    }
    
    .columnCon {
        width: 30%;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
    }
    
    .label {
        width: 100%;
        text-align: center;
        margin: 20px 0px;
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: medium;
    }
    
    .azmounContainer {
        width: 100%;
        min-height: 30px;
        background-color: #ade8f4;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .azmounContainer2 {
        width: 100%;
        min-height: 30px;
        background-color: #ef9a9a;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .azmounContainer3 {
        width: 100%;
        min-height: 30px;
        background-color: #80cbc4;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 3px 0px;
        cursor: pointer;
    }
    
    .azmounLabel {
        width: 90%;
        display: flex;
        margin: 10px 0px;
        padding: 0px 5px;
        justify-content: flex-start;
        align-items: center;
    }
    
    .azmounLabel h1 {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    .otherDetails {
        width: 90%;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        margin: 10px 0px;
        background-color: white;
    }
    
    .itemCon {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: x-small;
        margin-bottom: 10px;
    }