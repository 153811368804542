.userDetailsContainer {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
}

.userDetailsContainer .userIconCon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    /* border: 2px solid #d84b74; */
    border: 2px solid #da677f;
    color: #333;
    font-size: 25px;
    cursor: pointer;
}

.profilePic {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.DetailsPan {
    position: absolute;
    z-index: 5;
    top: 105%;
    min-height: 500px;
    min-width: 300px;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
}

.DetailsPan .detailsItem {
    width: 95%;
    padding: 7px 10px;
    background-color: #ade8f4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 7px;
}

.DetailsPan .detailsItem .label {
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    color: #333;
}

.DetailsPan .detailsItem .data {
    font-family: "vazir", sans-serif;
    font-weight: 400;
    font-size: medium;
    color: #333;
}

.accountDetailsCon {
    width: 90%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.accountItem {
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    border: 2px solid #023e8a;
    margin: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.accountItem .label {
    width: 100%;
    height: 30%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: x-small;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountItem .data {
    width: 100%;
    height: 70%;
    text-align: center;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exitBtn {
    width: 50%;
    height: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: "vazir", sans-serif;
    font-size: small;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d6396f;
    margin-top: 25px;
    cursor: pointer;
}