.Container {
    width: 100vw;
    height: 100vh;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 20px 0px;
}

.headerContain {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.headerContain .right {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.headerContain .left {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContain {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.mainContent {
    width: 70%;
    max-width: 1280px;
    min-width: 310px;
    height: 100%;
    min-height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

.mainContent ::-webkit-scrollbar {
    display: none;
}

@-moz-document url-prefix() {
    .mainContent {
        background: rgba(255, 255, 255, 0.884);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 1px solid rgba(255, 255, 255, 0.32);
    }
}

.menuContain {
    width: 20%;
    max-width: 1280px;
    min-width: 310px;
    height: 100%;
    min-height: 500px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.menuContain .menuBtnD {
    width: 110px;
    height: 60px;
    border-radius: 7px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 2px solid #077bae;
    transition: all 0.3s;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
}

@-moz-document url-prefix() {
    .menuContain .menuBtnD {
        background: rgba(255, 255, 255, 0.884);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 2px solid #14097b;
    }
}

.menuContain .menuBtnA {
    width: 120px;
    height: 70px;
    border-radius: 7px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.53);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 4px solid #e13e76;
    transition: all 0.3s;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
}

@-moz-document url-prefix() {
    .menuContain .menuBtnA {
        background: rgba(255, 255, 255, 0.884);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(4.5px);
        border: 4px solid #e13e76;
    }
}

.menuContain .menuBtnD:hover {
    transform: scale(1.1) translate(-10px, 0px);
}