    .container {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-family: "vazir", sans-serif;
        padding-bottom: 100px;
    }
    
    .banerContainer {
        width: 70%;
        min-width: 280px;
        max-width: 500px;
        height: 30%;
        min-height: 100px;
        max-height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
    }
    
    .banerPic {
        width: 100%;
        height: 100%;
        border-radius: 7px;
    }
    
    .acountContainer {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
    }
    
    .acountContainer .itemsContain {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin: 10px 0px;
    }
    
    .azmunakContainer {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .azmunakContainer h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #fe5c4d;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .azmunakContainer .itemsContain {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .azmunakContainer .itemsContain .content {
        padding: 5px;
        border-radius: 5px;
        background-color: #fd867c;
    }
    
    .pishazmunContainer {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .pishazmunContainer h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #7678ff;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .pishazmunContainer .itemsContain {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .pishazmunContainer .itemsContain .content {
        padding: 5px;
        border-radius: 5px;
        background-color: #9fa0f8;
    }
    
    .nightTestContainer {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .nightTestContainer h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #3c7165;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .nightTestContainer .itemsContain {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .nightTestContainer .itemsContain .content {
        padding: 5px;
        border-radius: 5px;
        background-color: #678c84;
    }
    
    .moshaverehContainer {
        width: 210px;
        min-width: 210px;
        max-width: 210px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        background-color: white;
        padding: 10px;
    }
    
    .moshaverehContainer h1 {
        font-family: "vazir", sans-serif;
        font-weight: 800;
        font-size: large;
        background-color: #717171;
        border-radius: 7px;
        padding: 7px 10px;
        margin-bottom: 10px;
    }
    
    .moshaverehContainer .itemsContain {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-family: "vazir", sans-serif;
        color: #333;
        margin-bottom: 10px;
    }
    
    .moshaverehContainer .itemsContain .content {
        padding: 5px;
        border-radius: 5px;
        background-color: #a3a3a3;
    }
    
    .itemsContain .label {
        font-weight: 800;
        font-size: large;
        margin-left: 5px;
    }
    
    .itemsContain .label {
        font-weight: 500;
        font-size: large;
    }
    
    .buyAccount {
        width: 170px;
        min-width: 170px;
        max-width: 170px;
        height: 170px;
        min-height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .buyAccountBtn {
        width: 150px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1a0f91;
        color: white;
        font-weight: 900;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 7px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    /* ---------------------------- */
    
    .noAcount {
        width: 80%;
        text-align: center;
        font-family: "vazir", sans-serif;
        font-weight: 900;
        font-size: x-large;
        color: #333;
    }