.Container1 {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.Container1 .ContainerOver {
    width: 100vw;
    min-width: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.096);
}

.Container1 .ContainerOver .Container2 {
    width: 100vw;
    max-width: 1280px;
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.Container1 .ContainerOver .Container2 ::-webkit-scrollbar {
    display: none;
}

.headerContain {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.headerContain .right {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.headerContain .left {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.contentContain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.contentLeft {
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: top;
    margin: 15px;
    /* cursor: pointer; */
    transition: all 0.3s;
    padding: 30px;
}

.contentLeft h1 {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: xx-large;
    margin-bottom: 25px;
    background-color: #e46b8b;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
}

.contentRight {
    width: 350px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #e46b8b;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid #e46b8bc0;
    font-family: "vazir", sans-serif;
    font-weight: 600;
    font-size: medium;
    line-height: 0.8cm;
    text-align: center;
    color: #333;
    margin: 15px;
    transition: all 0.3s;
    padding: 30px;
}

.mainLabel {
    width: 100%;
    padding: 15px;
    border-bottom: 0.5mm solid #ff769b;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: large;
    color: white;
    padding-top: 0;
}

.contentRight button {
    width: 80%;
    background-color: hsl(349, 100%, 85%);
    color: #333;
    font-weight: 800;
    font-size: medium;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    margin: 20px 0px;
}

.contentRight .selectedCourse {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.contentRight .selectedCourse::-webkit-scrollbar {
    display: none;
}

.startPishAzmoon {
    width: 100px;
    height: 60px;
    border-radius: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-bottom: 0.5mm solid #ff769b;
    background-color: #e46b8b;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: medium;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.selectContain {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}


/* ---------- */

.taggoleContain {
    width: 40px;
    height: 14px;
    border: 0.1mm solid rgb(83, 83, 83);
    position: relative;
    border-radius: 20px;
    margin-left: 10px;
}

.taggoleContain .buttonOfTaggole {
    width: 17px;
    height: 17px;
    top: -2px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    position: absolute;
    transition: all 0.5ms;
    z-index: 3;
    cursor: pointer;
}

.taggoleActive {
    left: 0;
    background-color: #ff769b;
}

.taggoleDeActive {
    right: 0;
    background-color: rgb(94, 93, 93);
}

.selectContain .item2 {
    width: 50%;
    height: 30px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
}

.numberInput {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    cursor: pointer;
    font-family: "vazir", sans-serif;
    font-weight: 500;
    font-size: small;
    color: #333;
    outline: none;
    border: none;
    text-align: center;
}