.detailsItem3 {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
}

.detailsItem3 .kindazmunContain {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.detailsItem3 .kindazmunContain .content {
    width: 90%;
    font-weight: 900;
    font-size: x-large;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.detailsItem3 .detailsazmunContain {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.detailsItem3 .detailsazmunContain .label {
    font-weight: 700;
    margin-left: 4px;
}

.detailsItem3 .timeazmunContain {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}