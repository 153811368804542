.dailyTextContainer {
    width: 90%;
    height: 80px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    color: #333;
    text-align: center;
    line-height: 0.8cm;
}