div.nc {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    font-weight: 900;
    font-size: xx-large;
    z-index: 404;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
}