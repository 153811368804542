 .detailsItem2 {
     width: 100%;
     height: 335px;
     background-color: white;
     border-radius: 15px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
     align-items: flex-start;
     justify-content: flex-start;
     padding: 10px 5px;
     margin-bottom: 10px;
     overflow-x: auto;
     -ms-overflow-style: none;
     scrollbar-width: none;
     font-family: "vazir", sans-serif;
 }
 
 .detailsItem2::-webkit-scrollbar {
     display: none;
 }
 
 .sumeryAnswer {
     padding: 3px;
     border-radius: 7px;
     background-color: #cfd8dc;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     margin-bottom: 3px;
     margin-left: 3px;
     cursor: pointer;
 }
 
 .sumeryAnswerActive {
     padding: 3px;
     border-radius: 7px;
     background-color: #cfd8dc;
     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
     margin-bottom: 3px;
     margin-left: 3px;
     cursor: pointer;
     border: 0.4mm solid black;
 }
 
 .Qnumber {
     margin-left: 3px;
     padding-top: 3px;
     font-size: xx-small;
 }
 
 .Qa1 {
     border-radius: 5px;
     background-color: white;
     border: 0.3mm solid black;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 100;
     font-size: 6px;
     margin-left: 3px;
     width: 15px;
     padding-top: 2px;
 }
 
 .Qa1An {
     border-radius: 5px;
     background-color: rgb(53, 53, 53);
     border: 0.3mm solid black;
     color: white;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 100;
     font-size: 6px;
     margin-left: 3px;
     width: 15px;
     padding-top: 2px;
 }