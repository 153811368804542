.azmoonCon {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    padding: 7px;
}

.azmoonCon .part1Con {
    width: 20%;
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
}

.azmoonCon .part1Con::-webkit-scrollbar {
    display: none;
}

.azmoonCon .part2Con {
    width: 60%;
    max-width: 900px;
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
    position: relative;
}

.azmoonCon .part2Con::-webkit-scrollbar {
    display: none;
}

.azmoonCon .part3Con {
    width: 20%;
    max-width: 300px;
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 5px;
}

.azmoonCon .part3Con::-webkit-scrollbar {
    display: none;
}


/* ----------- */

.endExamBtn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    background-color: #00e676;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
}

.discardExamBtn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    border-radius: 15px;
    background-color: #ff1744;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
}


/* ========================= */

.nextQuestion {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(51, 51, 51, 0.503);
    color: white;
    top: calc(50% - 25px);
    right: 2px;
    z-index: 3;
    cursor: pointer;
    border-radius: 5px;
}

.prevQuestion {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(51, 51, 51, 0.503);
    color: white;
    top: calc(50% - 25px);
    left: 2px;
    z-index: 3;
    cursor: pointer;
    border-radius: 5px;
}