.CurentTimeContainer {
    width: 100%;
    height: 80px;
    min-height: 80px;
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border: 1px solid rgba(255, 255, 255, 0.32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "vazir", sans-serif;
    margin-bottom: 10px;
}

.CurentTimeContainer .showTimeCon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.CurentTimeContainer .showTimeCon .dayOfWeek {
    background-color: #00b4d8;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
    margin-left: 5px;
}

.CurentTimeContainer .showTimeCon .timeNow {
    background-color: #90e0ef;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color: white;
    font-size: small;
}

.CurentTimeContainer .showDataCon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
}

.CurentTimeContainer .showDataCon>div {
    padding: 5px;
    margin-left: 3px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-size: small;
    font-weight: 500;
    background-color: white;
    color: #333333;
}