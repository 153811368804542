.box3bCon {
    width: 100%;
    height: 270px;
    max-height: 270px;
    min-height: 270px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px;
    margin-bottom: 10px;
    font-family: "vazir", sans-serif;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.box3bCon::-webkit-scrollbar {
    display: none;
}

.mainLabel {
    font-family: "vazir", sans-serif;
    font-weight: 800;
    font-size: large;
    margin-bottom: 5px;
}

.eachCourseCon {
    width: 95%;
    height: 100px;
    min-height: 100px;
    background-color: #90e0ef;
    border-radius: 5px;
    margin-bottom: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "vazir", sans-serif;
    color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.eachCourseCon::-webkit-scrollbar {
    display: none;
}

.detailsazmunContain {
    width: 95%;
    padding: 3px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: small;
}

.detailsazmunContain .label {
    font-weight: 700;
}

.mabahesCon {
    width: 95%;
    padding: 3px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
}

.mabahesCon .label {
    margin-bottom: 4px;
    font-weight: 700;
}

.mabahesCon .content {
    width: 95%;
    text-align: center;
    font-size: x-small;
}